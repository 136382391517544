/**
 * this file is auto generated. please run`npx nx run freshbuffer:generate-styles` to update this content.
 */

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

strong,
b {
  font-weight: 700;
}

body {
  margin: 0;
  color: #3A3A3C;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 1.75rem;
  background-color: #fff;
}
@media print {
  body {
    background-color: #fff;
  }
}

body::backdrop {
  background-color: #fff;
}

:root {
  color-scheme: light;

  --mui-breakpoints-values-xs: 0;
  --mui-breakpoints-values-sm: 600px;
  --mui-breakpoints-values-md: 1024px;
  --mui-breakpoints-values-lg: 1360px;
  --mui-breakpoints-values-xl: 1680px;
  --mui-direction: ltr;
  --mui-toolbar-minHeight: 56px;
  --mui-palette-common-black: #000;
  --mui-palette-common-white: #fff;
  --mui-palette-text-primary: #3A3A3C;
  --mui-palette-text-secondary: #989898;
  --mui-palette-action-main: #0071E3;
  --mui-palette-action-light: #0077ED;
  --mui-palette-action-dark: #006EDB;
  --mui-palette-primary-main: #F5F5F7;
  --mui-palette-primary-light: #EBEBF0;
  --mui-palette-primary-dark: #D1D1D6;
  --mui-palette-primary-contrastText: #fff;
  --mui-palette-secondary-main: #3A3A3C;
  --mui-palette-secondary-light: #7C7C80;
  --mui-palette-secondary-dark: #1C1C1E;
  --mui-palette-secondary-contrastText: #fff;
  --mui-palette-tertiary-main: #f8c051;
  --mui-palette-tertiary-light: #ffd873;
  --mui-palette-tertiary-dark: #5e4006;
  --mui-palette-tertiary-contrastText: #fff;
  --mui-palette-success-main: #34C759;
  --mui-palette-success-light: #3EEE74;
  --mui-palette-success-dark: #30D158;
  --mui-palette-success-contrastText: #fff;
  --mui-palette-danger-main: #cb302c;
  --mui-palette-danger-light: #e94944;
  --mui-palette-danger-dark: #ac0400;
  --mui-palette-danger-contrastText: #fff;
  --mui-palette-warning-main: #e86108;
  --mui-palette-warning-light: #fc7d21;
  --mui-palette-warning-dark: #c75300;
  --mui-palette-warning-contrastText: #fff;
  --mui-palette-mono-main: #e2e9ee;
  --mui-palette-mono-light: #f2f7fb;
  --mui-palette-mono-dark: #cedbe3;
  --mui-palette-mono-contrastText: #fff;
  --mui-palette-neutral-main: #000;
  --mui-palette-neutral-light: #fff;
  --mui-palette-neutral-dark: undefined;
  --mui-palette-neutral-contrastText: #fff;
  --mui-palette-divider: rgba(0, 0, 0, 0.12);
  --mui-palette-background-paper: #fff;
  --mui-palette-background-default: #fff;
  --mui-palette-action-active: rgba(0, 0, 0, 0.54);
  --mui-palette-action-hover: rgba(0, 0, 0, 0.04);
  --mui-palette-action-hoverOpacity: 0.04;
  --mui-palette-action-selected: rgba(0, 0, 0, 0.08);
  --mui-palette-action-selectedOpacity: 0.08;
  --mui-palette-action-disabled: rgba(0, 0, 0, 0.26);
  --mui-palette-action-disabledBackground: rgba(0, 0, 0, 0.12);
  --mui-palette-action-disabledOpacity: 0.38;
  --mui-palette-action-focus: rgba(0, 0, 0, 0.12);
  --mui-palette-action-focusOpacity: 0.12;
  --mui-palette-action-activatedOpacity: 0.12;
  --mui-shadows-0: none;
  --mui-shadows-1: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  --mui-shadows-2: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  --mui-shadows-3: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
  --mui-shadows-4: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  --mui-shadows-5: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
  --mui-shadows-6: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  --mui-shadows-7: 0 4px 5px -2px rgba(0, 0, 0, 0.2),
    0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mui-shadows-8: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mui-shadows-9: 0 5px 6px -3px rgba(0, 0, 0, 0.2),
    0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mui-shadows-10: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
    0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mui-shadows-11: 0 6px 7px -4px rgba(0, 0, 0, 0.2),
    0 11px 15px 1px rgba(0, 0, 0, 0.14), 0 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mui-shadows-12: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mui-shadows-13: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mui-shadows-14: 0 7px 9px -4px rgba(0, 0, 0, 0.2),
    0 14px 21px 2px rgba(0, 0, 0, 0.14), 0 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mui-shadows-15: 0 8px 9px -5px rgba(0, 0, 0, 0.2),
    0 15px 22px 2px rgba(0, 0, 0, 0.14), 0 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mui-shadows-16: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mui-shadows-17: 0 8px 11px -5px rgba(0, 0, 0, 0.2),
    0 17px 26px 2px rgba(0, 0, 0, 0.14), 0 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mui-shadows-18: 0 9px 11px -5px rgba(0, 0, 0, 0.2),
    0 18px 28px 2px rgba(0, 0, 0, 0.14), 0 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mui-shadows-19: 0 9px 12px -6px rgba(0, 0, 0, 0.2),
    0 19px 29px 2px rgba(0, 0, 0, 0.14), 0 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mui-shadows-20: 0 10px 13px -6px rgba(0, 0, 0, 0.2),
    0 20px 31px 3px rgba(0, 0, 0, 0.14), 0 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mui-shadows-21: 0 10px 13px -6px rgba(0, 0, 0, 0.2),
    0 21px 33px 3px rgba(0, 0, 0, 0.14), 0 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mui-shadows-22: 0 10px 14px -6px rgba(0, 0, 0, 0.2),
    0 22px 35px 3px rgba(0, 0, 0, 0.14), 0 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mui-shadows-23: 0 11px 14px -7px rgba(0, 0, 0, 0.2),
    0 23px 36px 3px rgba(0, 0, 0, 0.14), 0 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mui-shadows-24: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mui-typography-htmlFontSize: 16px;
  --mui-typography-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-fontSize: 14px;
  --mui-typography-fontWeightLight: 300;
  --mui-typography-fontWeightRegular: 400;
  --mui-typography-fontWeightMedium: 500;
  --mui-typography-fontWeightBold: 700;
  --mui-typography-h1-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-h1-fontWeight: 700;
  --mui-typography-h1-fontSize: 2.75rem;
  --mui-typography-h1-lineHeight: 3.5rem;
  --mui-typography-h2-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-h2-fontWeight: 700;
  --mui-typography-h2-fontSize: 2.25rem;
  --mui-typography-h2-lineHeight: 2.75rem;
  --mui-typography-h3-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-h3-fontWeight: 700;
  --mui-typography-h3-fontSize: 1.75rem;
  --mui-typography-h3-lineHeight: 2.25rem;
  --mui-typography-h4-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-h4-fontWeight: 700;
  --mui-typography-h4-fontSize: 1.5rem;
  --mui-typography-h4-lineHeight: 2rem;
  --mui-typography-h5-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-h5-fontWeight: 700;
  --mui-typography-h5-fontSize: 1.25rem;
  --mui-typography-h5-lineHeight: 1.75rem;
  --mui-typography-h6-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-h6-fontWeight: 700;
  --mui-typography-h6-fontSize: 1rem;
  --mui-typography-h6-lineHeight: 1.5rem;
  --mui-typography-subtitle1-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-subtitle1-fontWeight: 600;
  --mui-typography-subtitle1-fontSize: 1.125rem;
  --mui-typography-subtitle1-lineHeight: 1.75rem;
  --mui-typography-subtitle2-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-subtitle2-fontWeight: 600;
  --mui-typography-subtitle2-fontSize: 1rem;
  --mui-typography-subtitle2-lineHeight: 1.5rem;
  --mui-typography-subtitle3-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-subtitle3-fontWeight: 600;
  --mui-typography-subtitle3-fontSize: 0.875rem;
  --mui-typography-subtitle3-lineHeight: 1.25rem;
  --mui-typography-body1-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-body1-fontWeight: 400;
  --mui-typography-body1-fontSize: 1.125rem;
  --mui-typography-body1-lineHeight: 1.75rem;
  --mui-typography-body2-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-body2-fontWeight: 400;
  --mui-typography-body2-fontSize: 1rem;
  --mui-typography-body2-lineHeight: 1.5rem;
  --mui-typography-body3-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-body3-fontWeight: 400;
  --mui-typography-body3-fontSize: 0.875rem;
  --mui-typography-body3-lineHeight: 1.25rem;
  --mui-typography-body4-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-body4-fontWeight: 400;
  --mui-typography-body4-fontSize: 0.75rem;
  --mui-typography-body4-lineHeight: 1rem;
  --mui-typography-body1Bold-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-body1Bold-fontWeight: 700;
  --mui-typography-body1Bold-fontSize: 1.125rem;
  --mui-typography-body1Bold-lineHeight: 1.75rem;
  --mui-typography-body2Bold-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-body2Bold-fontWeight: 700;
  --mui-typography-body2Bold-fontSize: 1rem;
  --mui-typography-body2Bold-lineHeight: 1.5rem;
  --mui-typography-body3Bold-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-body3Bold-fontWeight: 700;
  --mui-typography-body3Bold-fontSize: 0.875rem;
  --mui-typography-body3Bold-lineHeight: 1.25rem;
  --mui-typography-body4Bold-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-body4Bold-fontWeight: 700;
  --mui-typography-body4Bold-fontSize: 0.75rem;
  --mui-typography-body4Bold-lineHeight: 1rem;
  --mui-typography-buttonLarge-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-buttonLarge-fontWeight: 600;
  --mui-typography-buttonLarge-fontSize: 1.125rem;
  --mui-typography-buttonLarge-lineHeight: 1.5rem;
  --mui-typography-buttonLarge-textTransform: none;
  --mui-typography-buttonMedium-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-buttonMedium-fontWeight: 600;
  --mui-typography-buttonMedium-fontSize: 1rem;
  --mui-typography-buttonMedium-lineHeight: 1.25rem;
  --mui-typography-buttonMedium-textTransform: none;
  --mui-typography-buttonSmall-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-buttonSmall-fontWeight: 600;
  --mui-typography-buttonSmall-fontSize: 0.875rem;
  --mui-typography-buttonSmall-lineHeight: 1rem;
  --mui-typography-buttonSmall-textTransform: none;
  --mui-typography-buttonXSmall-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-buttonXSmall-fontWeight: 600;
  --mui-typography-buttonXSmall-fontSize: 0.75rem;
  --mui-typography-buttonXSmall-lineHeight: 1rem;
  --mui-typography-buttonXSmall-textTransform: none;
  --mui-typography-linkLarge-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-linkLarge-fontWeight: 600;
  --mui-typography-linkLarge-fontSize: 1.125rem;
  --mui-typography-linkLarge-lineHeight: 1.5rem;
  --mui-typography-linkLarge-textTransform: undefined;
  --mui-typography-linkMedium-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-linkMedium-fontWeight: 600;
  --mui-typography-linkMedium-fontSize: 1rem;
  --mui-typography-linkMedium-lineHeight: 1.25rem;
  --mui-typography-linkMedium-textTransform: undefined;
  --mui-typography-linkSmall-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-linkSmall-fontWeight: 600;
  --mui-typography-linkSmall-fontSize: 0.875rem;
  --mui-typography-linkSmall-lineHeight: 1rem;
  --mui-typography-linkSmall-textTransform: undefined;
  --mui-typography-linkXSmall-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-linkXSmall-fontWeight: 600;
  --mui-typography-linkXSmall-fontSize: 0.75rem;
  --mui-typography-linkXSmall-lineHeight: 1rem;
  --mui-typography-linkXSmall-textTransform: undefined;
  --mui-typography-caption-fontFamily: 'Open Sans', Helvetica, Arial, sans-serif;
  --mui-typography-caption-fontWeight: 400;
  --mui-typography-caption-fontSize: 0.6875rem;
  --mui-typography-caption-lineHeight: 1rem;
  --mui-typography-overline-fontFamily: 'Open Sans', Helvetica, Arial,
    sans-serif;
  --mui-typography-overline-fontWeight: 400;
  --mui-typography-overline-fontSize: 0.6875rem;
  --mui-typography-overline-lineHeight: 1rem;
  --mui-typography-overline-textTransform: uppercase;
  --mui-spacing: 8px;
  --mui-transitions-easing-easeInOut: cubic-bezier(0.4, 0, 0.2, 1);
  --mui-transitions-easing-easeOut: cubic-bezier(0, 0, 0.2, 1);
  --mui-transitions-easing-easeIn: cubic-bezier(0.4, 0, 1, 1);
  --mui-transitions-easing-sharp: cubic-bezier(0.4, 0, 0.6, 1);
  --mui-transitions-duration-shortest: 150ms;
  --mui-transitions-duration-shorter: 200ms;
  --mui-transitions-duration-short: 250ms;
  --mui-transitions-duration-standard: 300ms;
  --mui-transitions-duration-complex: 375ms;
  --mui-transitions-duration-enteringScreen: 225ms;
  --mui-transitions-duration-leavingScreen: 195ms;
  --mui-zIndex-mobileStepper: 1000;
  --mui-zIndex-speedDial: 1050;
  --mui-zIndex-appBar: 1100;
  --mui-zIndex-drawer: 1200;
  --mui-zIndex-fab: 1050;
  --mui-zIndex-modal: 1300;
  --mui-zIndex-snackbar: 1400;
  --mui-zIndex-tooltip: 1500;
}

@media (min-width: 0) {
  @media (orientation: landscape) {
    :root {
      --mui-toolbar-minHeight: 48px;
    }
  }
}

@media (min-width: 600px) {
  :root {
    --mui-toolbar-minHeight: 64px;
  }
}

@custom-media --mui-breakpoint-xs-up (min-width: 0);
@custom-media --mui-breakpoint-xs-down (max-width: -0.05px);
@custom-media --mui-breakpoint-sm-up (min-width: 600px);
@custom-media --mui-breakpoint-sm-down (max-width: 599.95px);
@custom-media --mui-breakpoint-md-up (min-width: 1024px);
@custom-media --mui-breakpoint-md-down (max-width: 1023.95px);
@custom-media --mui-breakpoint-lg-up (min-width: 1360px);
@custom-media --mui-breakpoint-lg-down (max-width: 1359.95px);
@custom-media --mui-breakpoint-xl-up (min-width: 1680px);
@custom-media --mui-breakpoint-xl-down (max-width: 1679.95px);
