/**
 * this file is auto generated. please run`npx nx run freshbuffer:generate-styles` to update this content.
 */

:root {
  --design-bodyFontFamily: "Open Sans", Helvetica, Arial, sans-serif;
  --design-headerFontFamily: "Open Sans", Helvetica, Arial, sans-serif;
  --design-color-common-black: #000;
  --design-color-common-white: #fff;
  --design-color-common-transparentGray: rgba(255, 255, 255, 0.2);
  --design-color-text-primary: #3A3A3C;
  --design-color-text-secondary: #989898;
  --design-color-primary-contrastText: #fff;
  --design-color-primary-dark: #D1D1D6;
  --design-color-primary-main: #F5F5F7;
  --design-color-primary-light: #EBEBF0;
  --design-color-secondary-contrastText: #fff;
  --design-color-secondary-dark: #1C1C1E;
  --design-color-secondary-main: #3A3A3C;
  --design-color-secondary-light: #7C7C80;
  --design-color-tertiary-contrastText: #fff;
  --design-color-tertiary-dark: #FF9F0A;
  --design-color-tertiary-main: #FF9500;
  --design-color-tertiary-light: #FF9F0A;
  --design-color-action-dark: #006EDB;
  --design-color-action-main: #0071E3;
  --design-color-action-light: #0077ED;
  --design-color-success-contrastText: #fff;
  --design-color-success-dark: #30D158;
  --design-color-success-main: #34C759;
  --design-color-success-light: #3EEE74;
  --design-color-danger-contrastText: #fff;
  --design-color-danger-dark: #ac0400;
  --design-color-danger-main: #cb302c;
  --design-color-danger-light: #e94944;
  --design-color-warning-contrastText: #fff;
  --design-color-warning-dark: #c75300;
  --design-color-warning-main: #e86108;
  --design-color-warning-light: #fc7d21;
  --design-color-mono-contrastText: #fff;
  --design-color-mono-dark: #cedbe3;
  --design-color-mono-main: #e2e9ee;
  --design-color-mono-light: #f2f7fb;
  --design-color-neutral-contrastText: #fff;
  --design-color-neutral-main: #000;
  --design-color-neutral-overlay: rgba(2, 15, 40, 0.5);
  --design-color-neutral-gray: #99a3ac;
  --design-color-neutral-light: #fff;
  --design-breakpoint-xs: 0;
  --design-breakpoint-sm: 600;
  --design-breakpoint-md: 1024;
  --design-breakpoint-lg: 1360;
  --design-breakpoint-xl: 1680;
  --design-borderRadius-xs: 4px;
  --design-borderRadius-sm: 8px;
  --design-borderRadius-md: 16px;
  --design-borderRadius-lg: 24px;
  --design-borderRadius-xl: 32px;
  --design-fontSize-sm: 14px;
  --design-fontSize-md: 24px;
  --design-fontSize-lg: 40px;
}
